import FooterContainer_IronScreenComponent from '@wix/thunderbolt-elements/src/components/FooterContainer/viewer/skinComps/IronScreen/IronScreen.skin';


const FooterContainer_IronScreen = {
  component: FooterContainer_IronScreenComponent
};


export const components = {
  ['FooterContainer_IronScreen']: FooterContainer_IronScreen
};

